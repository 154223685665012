$(document).ready(function(){

  console.log('main.js is loaded!');

  $('.m-menu').click(function(){
    $('html, body').toggleClass('noscroll');
    $('body').toggleClass('m-menu-open');
  });

  winHeight = $(window).height();
  topPhotoHeight = winHeight - 140;
//  $('.top-block').css('height', topPhotoHeight).css('margin-top', 120);


});
